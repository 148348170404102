export default defineNuxtRouteMiddleware(async () => {
	const routeUrl = useRouteUrl()

	const user = useSupabaseUser().value

	if (user) {
		console.log("noauth-redirect")
		return navigateTo(routeUrl.HomeUrl())
	}
})
